module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Paris Cat Bookings","short_name":"Paris Cat Bookings","start_url":"/","background_color":"#1A202C","theme_color":"#1A202C","display":"minimal-ui","icon":"static/img/icon-hr.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"edf55f1a8fc36631ddb8f7d88f73cac2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
