import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { Container, Header, LogoLink, Wrapper, Body, Nav, NavLink, AccountName, Indicator } from './dashboardWrapperStyles'
import Icon from '../icon';
import { DomData } from 'utils/dom';

export const DashboardWrapper = ({path, children, user}) => {
    const { isBrowser } = DomData();
    const [ unpaidInvoice, setUnpaidInvoice] = useState(false);
    
    const handleLogOut = useCallback(() => {
        if (isBrowser) {
            window.localStorage.removeItem('dashboard-login-token');
            window.localStorage.removeItem('dashboard-login-email');
            window.location.reload();
        }
    }, [isBrowser]);

    useEffect(() => {
        setInterval(() => {
            if (window.dashboard.invoices?.length && !unpaidInvoice) {
                const unpaid = window.dashboard.invoices.filter((inv) => inv.paid === 'false');
                setUnpaidInvoice(unpaid?.length > 0)
            }
        }, 5000);
    }, []);

    const showInvoiceTab = useMemo(() => (
        user && (user === 'info@pariscat.com.au' || user === 'ashbettsdev@gmail.com')
    ), [user]);

    return useMemo(() => (
        <Container>
            <Header>
                <LogoLink to={`/dashboard/`}>
                    Paris Cat Bookings
                </LogoLink>
                <AccountName onClick={() => handleLogOut()}>Log Out</AccountName>
            </Header>
            <Wrapper>
                <Nav>
                    <NavLink to="/dashboard/" title={`Home`} active={path === '/dashboard/' ? 'true' : null}>
                        <Icon name={'home'} />
                    </NavLink>
                    <NavLink to="/dashboard/calendar" title={`Calendar`} active={path.includes('calendar') ? 'true' : null}>
                        <Icon name={'calendar'} />
                    </NavLink>
                    <NavLink to="/dashboard/events" title={`Events`} active={path.includes('events') ? 'true' : null}>
                        <Icon name={'speaker'} />
                    </NavLink>
                    <NavLink to="/dashboard/bookings" title={`Bookings`} active={path.includes('bookings') ? 'true' : null}>
                        <Icon name={'list'} />
                    </NavLink>
                    <NavLink to="/dashboard/customers" title={`Customers`} active={path.includes('customers') ? 'true' : null}>
                        <Icon name={'user'} />
                    </NavLink>
                    <NavLink to="/dashboard/gift-cards" title={`Gift Cards`} active={path.includes('gift-cards') ? 'true' : null}>
                        <Icon name={'gift'} />
                    </NavLink>
                    <NavLink to="/dashboard/settings" title={`Settings`} active={path.includes('settings') ? 'true' : null}>
                        <Icon name={'settings'} />
                    </NavLink>
                    {showInvoiceTab ? (
                        <NavLink to="/dashboard/invoices" title={`Invoices`} active={path.includes('invoices') ? 'true' : null}>
                            {unpaidInvoice ? (
                                <Indicator />
                            ) : null}
                            <Icon name={'invoice'} />
                        </NavLink>
                    ) : null}
                </Nav>
                <Body>
                    {children}
                </Body>
            </Wrapper>
        </Container>
    ), [path, children, showInvoiceTab, unpaidInvoice]);
};

