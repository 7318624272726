import { useState, useEffect } from 'react';

export const DomData = () => {
    const isBrowser = typeof(window) !== undefined && typeof(window) !== 'undefined';
    const [domLoaded, setDomLoaded] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    
    useEffect(() => {
        if (isBrowser) {
            function updateDomLoaded() {
                if (document.readyState === 'complete') {
                    setDomLoaded(true);
                }
            }
            if (!domLoaded) {
                document.addEventListener('readystatechange', updateDomLoaded);
                updateDomLoaded();
                return () => (
                    document.removeEventListener('readystatechange', updateDomLoaded)
                )
            }
        }
    }, [isBrowser, domLoaded]);

    useEffect(() => {
        if (modalOpen) {
            document.body.style.position = 'fixed';
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.position = 'relative';
            document.body.style.overflow = 'auto';
        }
    }, [modalOpen]);

    return {isBrowser, domLoaded, modalOpen, setModalOpen};
}