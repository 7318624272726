import React, { useMemo } from 'react'
import { DomData } from 'utils/dom';

const Icon = ({name}) => {
    const { isBrowser, domLoaded } = DomData();
    const IconElement = useMemo(() => (
        isBrowser && domLoaded && name ? React.lazy(() => import(`../icons/${name}.svg`)) : null
    ), [name, isBrowser, domLoaded]);
    
    return useMemo(() => IconElement ? (
        <React.Suspense fallback={<svg></svg>}>
            <IconElement />
        </React.Suspense>
    ) : null, [IconElement]);
}

export default Icon;
