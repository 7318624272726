exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dashboard-bookings-index-js": () => import("./../../../src/pages/dashboard/bookings/index.js" /* webpackChunkName: "component---src-pages-dashboard-bookings-index-js" */),
  "component---src-pages-dashboard-bookings-list-js": () => import("./../../../src/pages/dashboard/bookings/list.js" /* webpackChunkName: "component---src-pages-dashboard-bookings-list-js" */),
  "component---src-pages-dashboard-calendar-js": () => import("./../../../src/pages/dashboard/calendar.js" /* webpackChunkName: "component---src-pages-dashboard-calendar-js" */),
  "component---src-pages-dashboard-customers-index-js": () => import("./../../../src/pages/dashboard/customers/index.js" /* webpackChunkName: "component---src-pages-dashboard-customers-index-js" */),
  "component---src-pages-dashboard-customers-view-js": () => import("./../../../src/pages/dashboard/customers/view.js" /* webpackChunkName: "component---src-pages-dashboard-customers-view-js" */),
  "component---src-pages-dashboard-events-js": () => import("./../../../src/pages/dashboard/events.js" /* webpackChunkName: "component---src-pages-dashboard-events-js" */),
  "component---src-pages-dashboard-gift-cards-js": () => import("./../../../src/pages/dashboard/gift-cards.js" /* webpackChunkName: "component---src-pages-dashboard-gift-cards-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-dashboard-invoices-js": () => import("./../../../src/pages/dashboard/invoices.js" /* webpackChunkName: "component---src-pages-dashboard-invoices-js" */),
  "component---src-pages-dashboard-settings-js": () => import("./../../../src/pages/dashboard/settings.js" /* webpackChunkName: "component---src-pages-dashboard-settings-js" */),
  "component---src-pages-gift-cards-js": () => import("./../../../src/pages/gift-cards.js" /* webpackChunkName: "component---src-pages-gift-cards-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-templates-checkout-js": () => import("./../../../src/templates/checkout.js" /* webpackChunkName: "component---src-templates-checkout-js" */)
}

